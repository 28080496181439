   
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
 data: [],
}

export const userTypeSlice = createSlice({
 name: 'usertypes',
 initialState,
 reducers: {
  setUserTypes:(state,action)=>{
    state.data = action.payload; 
  }  
 },
})

// Action creators are generated for each case reducer function
export const { setUserTypes } = userTypeSlice.actions

export default userTypeSlice.reducer