import axiosHttp from "./axiosIntercepter"
import authHeader from "./auth-header"
import { API_URL } from "../constants/ConfigApi"

const getCheckin = (eventid, params) => {
	let url = 'checkin/get-event-types/' + eventid + '?page=' + (params?.page + 1 || 1)

	if (params?.limit) {
		url += ("&limit=" + params.limit)
	}
	if (params?.search) {
		url += ("&search=" + params.search)
	}

	return axiosHttp.get(API_URL + url, { headers: authHeader() })
}

export default {
	getCheckin
}
