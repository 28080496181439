import { Fragment } from "react"
import PropTypes from "prop-types"

import Box from "@mui/material/Box"
import Stack from "@mui/material/Stack"
import AppBar from "@mui/material/AppBar"
import Toolbar from "@mui/material/Toolbar"
import { useTheme } from "@mui/material/styles"
import IconButton from "@mui/material/IconButton"
import { Typography } from "@mui/material"

import useResponsive from "src/hooks/useResponsive"

import { bgBlur } from "src/theme/css"

import Iconify from "src/components/Iconify"
// import Searchbar from './Common/Searchbar'
import { NAV, HEADER } from "../../constants/ConfigLayout"
import AccountPopover from "./Common/AccountPopover"
import LanguagePopover from "./Common/LanguagePopover"
import NotificationsPopover from "./Common/NotificationsPopover"
import Logo from "src/components/Logo"

const Header = ({ onOpenNav }) => {
  const theme = useTheme()
  const lgUp = useResponsive("up", "lg")

  const renderContent = () => (
    <Fragment>
      {!lgUp && (
        <IconButton onClick={onOpenNav} sx={{ mr: 1 }}>
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>
      )}
      {/* <Searchbar /> */}
      <Typography
        variant="button"
        letterSpacing={2}
        sx={{ display: { xs: "none", sm: "block" }, color: "grey.600", fontSize: "36px" }}
      >
        EVENT
        <Logo sx={{ mt: 3 }} />
        PS
      </Typography>
      <Logo sx={{ display: { xs: "block", sm: "none" } }} />
      <Box sx={{ flexGrow: 1 }} />
      <Stack direction="row" alignItems="center" spacing={1}>
        <LanguagePopover />
        {/* <NotificationsPopover /> */}
        <AccountPopover />
      </Stack>
    </Fragment>
  )

  return (
    <AppBar
      sx={{
        boxShadow: "none",
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        transition: theme.transitions.create(["height"], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(lgUp && {
          width: `calc(100% - ${NAV.WIDTH + 1}px)`,
          height: HEADER.H_DESKTOP,
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
        }}
      >
        {renderContent()}
      </Toolbar>
    </AppBar>
  )
}

export default Header

Header.propTypes = {
  onOpenNav: PropTypes.func,
}
