import { lazy, Suspense } from "react"
import { Outlet, Navigate, useRoutes } from "react-router-dom"

import LoadingScreen from "src/components/LoadingScreen"
import DashboardLayout from "src/layouts/Dashboard"
import CheckinView from "src/module/EventOperations/Checkin"
import AppView from "src/module/Core"

export const IndexPage = lazy(() => import("src/module/Core"))
export const UsersView = lazy(() => import("src/module/Master/Users"))
export const RolesView = lazy(() => import("src/module/Master/Roles"))
export const InvitationsView = lazy(() => import("src/module/Invitations"))
export const Participants = lazy(() => import("src/module/Master/Participants"))
export const EventTypesView = lazy(() => import("src/module/Master/EventTypes"))
export const UserTypes = lazy(() => import("src/module/Master/UserTypes"))
export const Coupons = lazy(() => import("src/module/Master/Coupons"))
export const AttendeesView = lazy(() => import("src/module/EventOperations/Attendees"))
export const AttendeeDetails = lazy(
  () => import("src/module/EventOperations/Attendees/AttendeeDetails")
)
export const ConnectionsView = lazy(() => import("src/module/Connections"))
export const RsvpView = lazy(() => import("src/module/Rsvp"))
export const EventsView = lazy(() => import("src/module/Events"))
export const Profile = lazy(() => import("src/module/Profile"))
export const Organization = lazy(() => import("src/module/Organization"))
export const AddEditEvent = lazy(() => import("src/module/Events/AddEditEvent"))
export const EventDetails = lazy(() => import("src/module/Events/EventDetails"))
export const AddEditRsvp = lazy(() => import("src/module/Rsvp/AddEditRsvp"))
export const RsvpForm = lazy(() => import("src/module/Rsvp/components/RsvpForm"))
export const RsvpAttendeeForm = lazy(() => import("src/module/Rsvp/components/RsvpAttendeeForm"))
export const LoginView = lazy(() => import("src/module/Login"))
export const Register = lazy(() => import("src/module/Register"))
export const ForgotPassword = lazy(() => import("src/module/ForgotPassword"))
export const ResetPassword = lazy(() => import("src/module/ResetPassword"))
export const ActivateUser = lazy(() => import("src/module/ActivateUser"))
export const Page403 = lazy(() => import("src/module/Error/PageAccessDenied"))
export const Page404 = lazy(() => import("src/module/Error/PageNotFound"))
export const TicketsView = lazy(() => import("src/module/EventOperations/Tickets"))
export const EmailTemplates = lazy(() => import("src/module/Master/EmailTemplates"))
export const CompanyView = lazy(() => import("src/module/Company"))
export const PlansView = lazy(() => import("src/module/Plans"))
export const ReportsPage = lazy(() => import("src/module/Reports"))
export const PostEvent = lazy(() => import("src/module/PostEvent"))

export default function Router() {
  const routes = useRoutes([
    {
      path: "/app",
      element: (
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      ),
      children: [
        { element: <IndexPage />, index: true },
        { path: "dashboard", element: <AppView /> },
        { path: "view-users", element: <UsersView /> },
        { path: "view-events", element: <EventsView /> },
        { path: "add-events", element: <AddEditEvent /> },
        { path: "edit-events/:id", element: <AddEditEvent /> },
        { path: "add-rsvp", element: <AddEditRsvp /> },
        { path: "edit-rsvp/:id", element: <AddEditRsvp /> },
        { path: "view-rsvp/:id", element: <AddEditRsvp viewonly /> },
        { path: "view-rsvp", element: <RsvpView /> },
        { path: "view-connections", element: <ConnectionsView /> },
        { path: "view-attendees", element: <AttendeesView /> },
        { path: "view-attendee/:id", element: <AttendeeDetails /> },
        { path: "view-invitations", element: <InvitationsView /> },
        { path: "view-userroles", element: <RolesView /> },
        { path: "profile", element: <Profile /> },
        { path: "organization", element: <Organization /> },
        { path: "view-usertype", element: <UserTypes /> },
        { path: "view-coupons", element: <Coupons /> },
        { path: "view-eventtype", element: <EventTypesView /> },
        { path: "view-memberType", element: <Participants /> },
        { path: "view-tickets", element: <TicketsView /> },
        { path: "view-checkin", element: <CheckinView /> },
        { path: "view-emailtemplate", element: <EmailTemplates /> },
        { path: "view-company", element: <CompanyView /> },
        { path: "view-plans", element: <PlansView /> },
        { path: "view-reports", element: <ReportsPage /> },
        { path: "error/403", element: <Page403 /> },
        { path: "error/404", element: <Page404 /> },
        { path: "*", element: <Navigate to="/app/error/404" replace /> },
        { path: "post-event", element: <PostEvent /> },
      ],
    },
    { path: "login", element: <LoginView /> },
    { path: "register", element: <Register /> },
    { path: "forgot-password", element: <ForgotPassword /> },
    { path: "reset-password/:token", element: <ResetPassword /> },
    { path: "user-activate/:token", element: <ActivateUser /> },
    { path: "/rsvp/:recipient", element: <RsvpForm /> },
    { path: "/rsvp-attendee-form/:recipient", element: <RsvpAttendeeForm /> },
    {
      path: "/event-details/:id",
      element: (
        <Suspense fallback={<LoadingScreen />}>
          <EventDetails />
        </Suspense>
      ),
    },
    { path: "*", element: <Navigate to="/login" replace /> },
  ])

  return routes
}
