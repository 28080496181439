
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  data: [],
  requestData: []
}

export const invitationSlice = createSlice({
  name: 'invitations',
  initialState,
  reducers: {
    setInvitation: (state, action) => {
      state.data = action.payload
    },
    setRequestInvitation: (state, action) => {
      state.requestData = action.payload
    },
    addInvitation: (state, action) => {
      state.data = [...state.data, action.payload]
    }
  },
})

// Action creators are generated for each case reducer function
export const { setInvitation, setRequestInvitation, addInvitation } = invitationSlice.actions

export default invitationSlice.reducer