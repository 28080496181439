import PropTypes from 'prop-types'

import { Stack } from '@mui/material'

import { checkRolePermissions } from 'src/utils/checkRolePermissions'

import { usePermissions } from 'src/routes/PermissionRoute'

import NavItem from './NavItem'

const NavChild = ({ data }) => {
  const { userPermissions } = usePermissions()

  return (
    <Stack component="nav" spacing={0.75} sx={{ px: 2 }}>
      {data.map(
        (item) => checkRolePermissions(userPermissions, item?.id, 'isView') && <NavItem key={item.title} item={item} />
      )}
    </Stack>
  )
}

export default NavChild

NavChild.propTypes = {
  data: PropTypes.array,
}
