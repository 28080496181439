import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  data: [],
}

export const connectionSlice = createSlice({
  name: "connection",
  initialState,
  reducers: {
    setConnection: (state, action) => {
      state.data = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setConnection } = connectionSlice.actions

export default connectionSlice.reducer
