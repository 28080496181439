import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  draftsData: [],
  postedData: [],
  customFormData: [],
}

export const postEventSlice = createSlice({
  name: "postevents",
  initialState,
  reducers: {
    getDraftPostedEventSuccess: (state, action) => {
      state.draftsData = action.payload
      state.isLoading = false
    },
    getPostedEventSuccess: (state, action) => {
      state.postedData = action.payload
      state.isLoading = false
    },
    addPostEventSuccess: (state, action) => {
      state.draftsData.result.unshift(action.payload.result)
      state.isLoading = false
    },
    setCustomFormList: (state, action) => {
      state.customFormData = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  getDraftPostedEventSuccess,
  getPostedEventSuccess,
  addPostEventSuccess,
  setCustomFormList,
} = postEventSlice.actions

export default postEventSlice.reducer
