import _ from 'lodash'
import i18n from 'i18next'
import LanguageDetector from "i18next-browser-languagedetector"
import { initReactI18next, useTranslation } from 'react-i18next'

import en from './en'
import de from './de'
import fr from './fr'
import hi from './hi'
import gu from './gu'

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    },
    resources: {
      en,
      de,
      fr,
      hi,
      gu
    },
  })

const clonedi18n = _.cloneDeep(i18n)

clonedi18n.t = (key, options, ...props) => {
  useTranslation()

  return i18n.t(key, options, ...props)
}

export default clonedi18n
