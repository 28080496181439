import { Fragment, forwardRef } from 'react'
import PropTypes from 'prop-types'

import { Box } from '@mui/material'

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  const renderLogo = () => (
    <Box
      ref={ref}
      component="div"
      sx={{
        width: 30,
        height: 30,
        display: 'inline-flex',
        ...sx,
      }}
      {...other}
    >
      <img src='/assets/logo.png' alt='logo' />
    </Box>
  )

  if (disabledLink) {
    return renderLogo()
  }

  return (
    <Fragment>
      {renderLogo()}
    </Fragment>
  )
})

export default Logo

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
}
