import React, { Fragment, useState, useEffect } from "react"

import {
  Autocomplete,
  Box,
  Card,
  CardHeader,
  Grid,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material"

import Summary from "src/components/Dummy/Summary"

import PieChart from "src/components/Chart/PieChart"
import LineChart from "src/components/Chart/LineChart"
import BarChart from "src/components/Chart/BarChart"

import eventService from "src/services/event.service"
import { format } from "date-fns"
import Iconify from "src/components/Iconify"

const Analytics = ({ translations, filter, filterDate }) => {
  const [eventTypeOptionState, setEventTypeOptionState] = useState([])
  const [eventDetailsState, setEventDetailsState] = useState([])
  const [eventsWithAttendeesOption, setEventsWithAttendeesOption] = useState([])
  const [ticketPurchasedState, setTicketPurchasedState] = useState([])
  const [eventsForAttendeeBased, setEventsForAttendeeBased] = useState([])
  const [eventBasedTickets, setEventBasedTickets] = useState([])

  useEffect(() => {
    getEventTypeStatistics()
    getEventDetailsStatistics()
    getEventTicketStatistics()
    getEventAttendeeStatistics()
    getEventBasedAttendeeTickets("All")
    eventService
      .getAllEvents()
      .then((res) => {
        if (res.status) {
          const resData = [{ id: "All", eventTitle: translations.allEvents }]
          resData.push(...res.data.data)
          setEventsForAttendeeBased(resData)
        }
      })
      .catch((error) => console.error(error))
  }, [filterDate])

  const getEventTypeStatistics = () => {
    const data = {
      dashboardType: "eventType",
      ...filterDate,
    }

    eventService
      .getDashboard(data)
      .then((res) => {
        if (res.status) {
          const eventTypeOptions = {
            series: res.data.data.series,
            options: {
              legend: {
                show: false,
              },
              labels: res.data.data.labels,
            },
          }

          setEventTypeOptionState(eventTypeOptions)
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }

  const getEventDetailsStatistics = () => {
    const data = {
      dashboardType: "eventDetails",
      ...filterDate,
    }

    eventService
      .getDashboard(data)
      .then((res) => {
        if (res.status) {
          setEventDetailsState(res.data.data)
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }

  const getEventTicketStatistics = () => {
    const data = {
      dashboardType: "eventTicket",
      ...filterDate,
    }

    eventService
      .getDashboard(data)
      .then((res) => {
        if (res.status) {
          const ticketPurchasedOptions = {
            series: res.data.data,
            options: {
              chart: {
                height: 350,
                stacked: true,
                stackType: "normal",
              },
              xaxis: {
                type: "datetime",
              },
            },
          }

          setTicketPurchasedState(ticketPurchasedOptions)
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }

  const getEventAttendeeStatistics = () => {
    const data = {
      dashboardType: "eventAttendee",
      ...filterDate,
    }

    eventService
      .getDashboard(data)
      .then((res) => {
        if (res.status) {
          res.data.data.map((series) => {
            if (series.name === "offline") {
              series.color = "#FFAB00"
              series.name = translations.offline
            } else if (series.name === "online") {
              series.color = "#00A76F"
              series.name = translations.online
            } else if (series.name === "hybrid") {
              series.color = "#00B8D9"
              series.name = translations.hybrid
            }
          })
          const eventsWithAttendeesOptions = {
            series: res.data.data,
            options: {
              xaxis: {
                labels: {
                  formatter: function (value, timestamp) {
                    if (timestamp) {
                      return format(typeof timestamp === "number" ? timestamp : value, "dd MMM yy")
                    }
                  },
                },
                type: "datetime",
              },
            },
          }

          setEventsWithAttendeesOption(eventsWithAttendeesOptions)
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }

  const getEventBasedAttendeeTickets = (value) => {
    const data = {
      dashboardType: "attendeeByEvent",
      ...filterDate,
    }
    if (value.id != "All") {
      data.event = value.id
    }

    eventService
      .getDashboard(data)
      .then((res) => {
        if (res.status) {
          const resData = res.data.data
          const eventData = []
          resData.map((item) => {
            const index = eventData.findIndex((duplicate) => duplicate.id == item.id)
            if (index === -1) {
              eventData.push(item)
            } else {
              eventData[index].checkineventcount = item.checkineventcount
            }
          })
          const eventBasedTicketsOptions = {
            series: [
              {
                name: "Tickets",
                data: eventData.map((item) => item.ticketeventcount || 0),
              },
              {
                name: "Check-ins",
                data: eventData.map((item) => item.checkineventcount || 0),
              },
            ],
            options: {
              chart: {
                height: 350,
              },
              plotOptions: {
                bar: {
                  horizontal: false,
                  columnWidth: "55%",
                  endingShape: "rounded",
                },
              },
              stroke: {
                show: true,
                width: 2,
                colors: ["transparent"],
              },
              xaxis: {
                categories: eventData.map((item) => item.eventtitle),
                labels: {
                  formatter: (value) => {
                    return value?.length > 10 ? value.substring(0, 10) + "..." : value
                  }
                }
              },
              tooltip: {
                x: {
                  formatter: (val) => val
                }
              }
            },
          }

          setEventBasedTickets(eventBasedTicketsOptions)
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }

  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <Summary
            title={translations.events}
            items={[
              {
                label: translations.free,
                total:
                  parseInt(eventDetailsState?.singlefree) + parseInt(eventDetailsState?.multifree),
              },
              {
                label: translations.paid,
                total:
                  parseInt(eventDetailsState?.singlepaid) + parseInt(eventDetailsState?.multipaid),
              },
            ]}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Summary
            title={translations.eventsByModes}
            items={[
              {
                label: translations.offline,
                total: eventDetailsState?.offline,
              },
              {
                label: translations.online,
                total: eventDetailsState?.online,
              },
              {
                label: translations.hybrid,
                total: eventDetailsState?.hybrid,
              },
            ]}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Summary
            title={translations.attendees}
            items={[
              {
                label: translations.invited,
                total:
                  (Math.random() * 1000) / (filter === "Week" ? 7 : filter === "Month" ? 3 : 1),
              },
              {
                label: translations.requestedSocialMedia,
                total:
                  (Math.random() * 1000) / (filter === "Week" ? 7 : filter === "Month" ? 3 : 1),
              },
            ]}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Card sx={{ width: 1, height: 1 }}>
            <CardHeader
              sx={{ p: 1 }}
              title={
                <Typography variant="subtitle1">{translations.eventTypeStatistics}</Typography>
              }
            />
            <PieChart options={eventTypeOptionState.options} series={eventTypeOptionState.series} />
          </Card>
        </Grid>
        <Grid item xs={12} md={8}>
          <Card sx={{ width: 1, height: 1 }}>
            <CardHeader
              sx={{ p: 1 }}
              title={
                <Typography variant="subtitle1">{translations.noOfAttendeeStatistics}</Typography>
              }
            />
            <LineChart
              options={eventsWithAttendeesOption.options}
              series={eventsWithAttendeesOption.series}
            />
          </Card>
        </Grid>
        <Grid item xs={12} md={12}>
          <Card sx={{ width: 1, height: 1 }}>
            <CardHeader
              sx={{ p: 1 }}
              title={
                <Typography variant="subtitle1">
                  {translations.noOfTicketsPurchasedStatistics}
                </Typography>
              }
            />
            <BarChart options={ticketPurchasedState.options} series={ticketPurchasedState.series} />
          </Card>
        </Grid>
        <Grid item xs={12} md={12}>
          <Card sx={{ width: 1, height: 1 }}>
            <CardHeader
              sx={{ p: 0 }}
              title={
                <Stack spacing={1} alignItems="center" m={1} direction="row">
                  <Box flexGrow={1}>
                    <Typography variant="subtitle1">
                      {translations.eventBaseTicketAndCheckIn}
                    </Typography>
                  </Box>
                  <Autocomplete
                    disablePortal
                    disableClearable
                    defaultValue={eventsForAttendeeBased[0]}
                    size="small"
                    sx={{
                      minWidth: "20%",
                    }}
                    options={eventsForAttendeeBased}
                    getOptionLabel={(option) => option.eventTitle}
                    isOptionEqualToValue={(option, value) => option.id == value.id}
                    onChange={(event, value) => {
                      getEventBasedAttendeeTickets(value)
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                  <Tooltip title={translations.eventBaseTicketAndCheckInInfo}>
                    <IconButton>
                      <Iconify icon="ic:outline-info" />
                    </IconButton>
                  </Tooltip>
                </Stack>
              }
            />
            <BarChart options={eventBasedTickets.options} series={eventBasedTickets.series} />
          </Card>
        </Grid>
      </Grid>
    </Fragment>
  )
}

export default Analytics
