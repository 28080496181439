import React, { useEffect, useState } from "react"
import { CalendarView } from "src/components/Calendar/view"
import CryptoJS from "crypto-js"

import { palette as themePalette } from "src/theme/palette"

import eventService from "src/services/event.service"

const palette = themePalette("light")

const CALENDAR_COLOR_OPTIONS = [
  palette.primary.main,
  palette.secondary.main,
  palette.info.main,
  palette.info.darker,
  palette.success.main,
  palette.warning.main,
  palette.error.main,
  palette.error.darker,
]

const Calendar = () => {
  const [events, setEvents] = useState([])

  useEffect(() => {
    getAllEvents()
  }, [])

  const getAllEvents = () => {
    eventService
      .getAllEvents()
      .then((res) => {
        if (res.status) {
          const resData = res.data.data.map((event) => {
            event.start = new Date(event.eventStartDateTime)
            event.end = new Date(event.eventEndDateTime)
            event.allDay = false
            event.title = event.eventTitle
            let colorIndex =
              event.eventMode === "offline" ? 5 : event.eventMode === "online" ? 4 : 2
            colorIndex = event.isDraft ? 0 : event.isCanceled ? 6 : colorIndex
            event.textColor = CALENDAR_COLOR_OPTIONS[colorIndex]
            const encodedId = encodeURIComponent(CryptoJS.DES.encrypt(event.id, "my-secret-key@123"))
            event.url = `${window.location.origin}/event-details/${encodedId}`
            return event
          })
          setEvents(resData)
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }

  return <CalendarView events={events} />
}

export default Calendar
