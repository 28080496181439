import { merge } from "lodash"
import PropTypes from "prop-types"

import { DataGrid } from "@mui/x-data-grid"

import { bgBlur } from "src/theme/css"
import i18n from "src/translations"
import EmptyContent from "./../EmptyContent"

const defaultState = {
  pagination: {
    paginationModel: {
      pageSize: 10,
    },
  },
  sorting: {
    sortModel: [{ field: "createdAt", sort: "desc" }],
  },
}

const DataGridBasic = ({ data, columns, initialState, emptyContentTitle, ...others }) => (
  <DataGrid
    autoHeight
    sx={{
      ".MuiDataGrid-columnHeaders": {
        ...bgBlur({
          color: "#637381",
          opacity: 0.1,
        }),
      },
      ".MuiDataGrid-columnHeaderTitle": {
        fontWeight: 600,
      },
      "--DataGrid-overlayHeight": "300px",
    }}
    disableRowSelectionOnClick
    rows={data}
    columns={columns}
    slots={{
      noRowsOverlay: () => (
        <EmptyContent title={emptyContentTitle || i18n.t("emptyState:noRowsFound")} />
      ),
      noResultsOverlay: () => (
        <EmptyContent title={emptyContentTitle || i18n.t("emptyState:noRowsFound")} />
      ),
    }}
    initialState={merge(initialState, defaultState)}
    {...others}
  />
)

export default DataGridBasic

DataGridBasic.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.array,
}
