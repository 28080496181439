   
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
 data: [],
}

export const checkinSlice = createSlice({
 name: 'checkin',
 initialState,
 reducers: {
  setCheckin:(state,action)=>{
    state.data = action.payload; 
  }
 },
})

// Action creators are generated for each case reducer function
export const { setCheckin } = checkinSlice.actions

export default checkinSlice.reducer