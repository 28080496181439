   
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
 data: [],
}

export const companySlice = createSlice({
 name: 'company',
 initialState,
 reducers: {
  setCompany:(state,action)=>{
    state.data = action.payload; 
  }  
 },
})

// Action creators are generated for each case reducer function
export const { setCompany } = companySlice.actions

export default companySlice.reducer