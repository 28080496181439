import { merge } from "lodash"
import PropTypes from "prop-types"

import Chart, { useChart } from "src/components/Chart"

const PieChart = ({ series, options }) => {
  const chartOptions = useChart(
    merge(options, {
      chart: {
        width: "auto",
        type: "pie",
      },
      legend: {
        position: "right",
        offsetX: -20,
        offsetY: 64,
        itemMargin: {
          vertical: 8,
        },
      },
      stroke: {
        show: false,
      },
      dataLabels: {
        enabled: true,
        dropShadow: {
          enabled: false,
        },
      },
      tooltip: {
        fillSeriesColor: false,
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: false,
            },
          },
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: "auto",
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    })
  )

  return <Chart dir="ltr" type="pie" series={series || []} options={chartOptions} width={350} />
}

export default PieChart

PieChart.propTypes = {
  series: PropTypes.array,
  options: PropTypes.object,
}
