import { format, getTime, formatDistanceToNow } from "date-fns"
import { formatInTimeZone } from "date-fns-tz"

export const fDate = (date, newFormat) => {
  const fm = newFormat || "dd MMM yyyy"

  return date ? format(new Date(date), fm) : ""
}

export const fDateTime = (date, newFormat) => {
  const fm = newFormat || "dd MMM yyyy p"

  return date ? format(new Date(date), fm) : ""
}

export const fTimestamp = (date) => {
  return date ? getTime(new Date(date)) : ""
}

export const fToNow = (date) => {
  return date
    ? formatDistanceToNow(new Date(date), {
        addSuffix: true,
      })
    : ""
}

export const fToDateObj = (date_) => {
  return new Date(formatInTimeZone(date_, "", "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"))
}

export const ftoDate = (date_, type) => {
  let date = new Date()
  if (type == "date") {
    if (date_ === null) {
    } else {
      date.setDate(date_.split("-")[2])
      date.setMonth(parseInt(date_.split("-")[1]) - 1)
      date.setFullYear(date_.split("-")[0])
    }
  } else {
    date.setHours(date_.split(":")[0])
    date.setMinutes(date_.split(":")[1])
  }

  return date
}
