import createSagaMiddleware from "redux-saga"
import { configureStore } from "@reduxjs/toolkit"

import participantSlice from "./slices/masters/participantSlice"
import eventTypeSlice from "./slices/masters/eventTypeSlice"
import userTypeSlice from "./slices/masters/userTypeSlice"
import couponSlice from "./slices/masters/couponsSlice"
import userRoleSlice from "./slices/masters/userRoleSlice"
import companySlice from "./slices/masters/companySlice"

import userSlice from "./slices/users/userSlice"
import rsvpListSlice from "./slices/rsvp/rsvpListSlice"
import eventSlice from "./slices/events/eventSlice"
import invitationSlice from "./slices/invitations/invitationSlice"

import rootSaga from "./slices/rootSaga"
import connectionSlice from "./slices/connections/connectionSlice"
import formsSlice from "./slices/forms/formsSlice"
import groupSlice from "./slices/groups/groupSlice"
import postEventSlice from "./slices/post-event/postEventSlice"
import attendeesSlice from "./slices/attendees/attendessSlice"
import ticketSlice from "./slices/tickets/ticketSlice"
import checkinSlice from "./slices/checkin/checkinSlice"
import plansSlice from "./slices/plans/plansSlice"
import reportsSlice from "./slices/reports/reportsSlice"

const saga = createSagaMiddleware()

export const store = configureStore({
  reducer: {
    participants: participantSlice,
    eventtypes: eventTypeSlice,
    usertypes: userTypeSlice,
    coupons: couponSlice,
    userroles: userRoleSlice,
    company: companySlice,
    users: userSlice,
    rsvplist: rsvpListSlice,
    events: eventSlice,
    invitations: invitationSlice,
    connections: connectionSlice,
    forms: formsSlice,
    groups: groupSlice,
    attendees: attendeesSlice,
    tickets: ticketSlice,
    checkin: checkinSlice,
    plans: plansSlice,
    postevents: postEventSlice,
    reports: reportsSlice,
  },
  middleware: [saga],
})

saga.run(rootSaga)
