   
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
 data: [],
}

export const eventTypeSlice = createSlice({
 name: 'eventtypes',
 initialState,
 reducers: {
  setEventTypes:(state,action)=>{
    state.data = action.payload; 
  }  
 },
})

// Action creators are generated for each case reducer function
export const { setEventTypes } = eventTypeSlice.actions

export default eventTypeSlice.reducer