export const LANGS = [
  {
    value: 'en',
    label: 'English',
    icon: '/assets/icons/ic_flag_en.svg',
  },
  {
    value: 'hi',
    label: 'हिंदी',
    icon: '/assets/icons/ic_flag_in.svg',
  },
  {
    value: 'gu',
    label: 'ગુજરાતી',
    icon: '/assets/icons/ic_flag_in.svg',
  },
  {
    value: 'de',
    label: 'Deutsch',
    icon: '/assets/icons/ic_flag_de.svg',
  },
  {
    value: 'fr',
    label: 'Français',
    icon: '/assets/icons/ic_flag_fr.svg',
  },
]

export const LANGS_FLAG = {
  en: LANGS[0],
  hi: LANGS[1],
  gu: LANGS[2],
  de: LANGS[3],
  fr: LANGS[4],
}
