import PropTypes from 'prop-types'

import { Box, Link, Breadcrumbs, Stack, Typography } from '@mui/material'

import LinkItem from './LinkItem'
import Separator from './Separator'

const CustomBreadcrumbs = ({
  links,
  action,
  heading,
  moreLink,
  activeLast,
  sx,
  ...other
}) => {
  const lastLink = links[links.length - 1].name

  return (
    <Box sx={{ ...sx }}>
      <Stack direction="row" alignItems="center">
        <Box sx={{ flexGrow: 1 }}>
          {heading && (
            <Typography variant="h4" gutterBottom>
              {heading}
            </Typography>
          )}
          {!!links.length && (
            <Breadcrumbs separator={<Separator />} {...other}>
              {links.map((link) => (
                <LinkItem
                  key={link.name || ''}
                  link={link}
                  activeLast={activeLast}
                  disabled={link.name === lastLink}
                />
              ))}
            </Breadcrumbs>
          )}
        </Box>
        {action && <Box sx={{ flexShrink: 0 }}> {action} </Box>}
      </Stack>
      {!!moreLink && (
        <Box sx={{ mt: 2 }}>
          {moreLink.map((href) => (
            <Link
              key={href}
              href={href}
              variant="body2"
              target="_blank"
              rel="noopener"
              sx={{ display: 'table' }}
            >
              {href}
            </Link>
          ))}
        </Box>
      )}
    </Box>
  )
}

export default CustomBreadcrumbs

CustomBreadcrumbs.propTypes = {
  sx: PropTypes.object,
  action: PropTypes.node,
  links: PropTypes.array,
  heading: PropTypes.string,
  moreLink: PropTypes.array,
  activeLast: PropTypes.bool,
}
