export const checkRolePermissions = (permissions, id, route = null) => {
  const moduleData = permissions?.find((x) => x.moduleName === id)

  if (moduleData) {
    if (route) {
      return moduleData[route]
    } else {
      if (!moduleData.isAdd && !moduleData.isDelete && !moduleData.isEdit && !moduleData.isView)
        return false
      if (moduleData.isAdd && moduleData.isDelete && moduleData.isEdit && moduleData.isView)
        return true
    }
  } else if (["masters", "eventoperations", "dashboard", "postevent", "reports"].includes(id)) {
    return true
  }

  return false
}
