import { Fragment, useState } from "react"

import Box from "@mui/material/Box"
import Avatar from "@mui/material/Avatar"
import Divider from "@mui/material/Divider"
import Popover from "@mui/material/Popover"
import { alpha } from "@mui/material/styles"
import MenuItem from "@mui/material/MenuItem"
import Typography from "@mui/material/Typography"
import IconButton from "@mui/material/IconButton"

import { useRouter } from "src/routes/hooks"
import authService from "src/services/auth.service"
import { useSnackbar } from "src/components/Snackbar"
import i18n from "src/translations"

const AccountPopover = () => {
  const MENU_OPTIONS = [
    {
      label: i18n.t("global:home"),
      icon: "eva:home-fill",
      path: "/app/dashboard",
    },
    {
      label: i18n.t("global:profile"),
      icon: "eva:person-fill",
      path: "/app/profile",
    },
    {
      label: i18n.t("organization:myOrganization"),
      icon: "eva:person-fill",
      path: "/app/organization",
    },
  ]
  const logoutMsg = i18n.t("toast:success:logout")
  const { enqueueSnackbar } = useSnackbar()
  const [open, setOpen] = useState(null)
  const [account, setAccount] = useState(JSON.parse(localStorage.getItem("currentUser")))
  const router = useRouter()

  const handleOpen = (event) => {
    setOpen(event.currentTarget)
  }

  const handleClose = (path) => {
    setOpen(null)

    if (typeof path === "string") {
      router.push(path)
    }
  }

  const logout = async () => {
    setOpen(null)

    enqueueSnackbar(logoutMsg)

    await authService.logout()

    router.push("/login")
  }

  return (
    <Fragment>
      <IconButton
        onClick={handleOpen}
        sx={{
          width: 40,
          height: 40,
          background: (theme) => alpha(theme.palette.grey[500], 0.08),
          ...(open && {
            background: (theme) =>
              `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
          }),
        }}
      >
        <Avatar
          src={account?.profilePic}
          alt={account?.firstName}
          sx={{
            width: 36,
            height: 36,
            border: (theme) => `solid 2px ${theme.palette.background.default}`,
          }}
        >
          {account?.firstName.charAt(0).toUpperCase()}
        </Avatar>
      </IconButton>

      <Popover
        open={!!open}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1,
            ml: 0.75,
            width: 200,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2 }}>
          <Typography variant="subtitle2" noWrap>
            {account?.firstName}
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
            {account?.email}
          </Typography>
        </Box>
        <Divider sx={{ borderStyle: "dashed" }} />
        {MENU_OPTIONS.map((option) => (
          <MenuItem key={option.label} onClick={() => handleClose(option.path)}>
            {option.label}
          </MenuItem>
        ))}
        <Divider sx={{ borderStyle: "dashed", m: 0 }} />
        <MenuItem
          disableRipple
          disableTouchRipple
          onClick={logout}
          sx={{ typography: "body2", color: "error.main", py: 1.5 }}
        >
          {i18n.t("global:logout")}
        </MenuItem>
      </Popover>
    </Fragment>
  )
}

export default AccountPopover
