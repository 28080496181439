import { useMemo } from 'react'
import PropTypes from 'prop-types'

import CssBaseline from '@mui/material/CssBaseline'
import { createTheme, ThemeProvider as MUIThemeProvider } from '@mui/material/styles'

import { palette } from './palette'
import { shadows } from './shadows'
import { overrides } from './overrides'
import { typography } from './typography'
import { customShadows } from './customShadows'

const ThemeProvider = ({ children }) => {
  const memoizedValue = useMemo(
    () => ({
      palette: palette(),
      typography,
      shadows: shadows(),
      customShadows: customShadows(),
      shape: { borderRadius: 8 },
    }),
    []
  )

  const theme = createTheme(memoizedValue)

  theme.components = overrides(theme)

  return (
    <MUIThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </MUIThemeProvider>
  )
}

export default ThemeProvider

ThemeProvider.propTypes = {
  children: PropTypes.node,
}
