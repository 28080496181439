export default {
  global: {
    welcome: "Hi, Welcome back",
    admin: "Admin",
    dashboard: "Dashboard",
    events: "Events",
    rsvp: "RSVP",
    connections: "Connections",
    groups: "Groups",
    invitation: "Invitation",
    eventOperations: "Event Operations",
    master: "Master",
    reports: "Reports",
    postEvent: "Post Event",
    attendees: "Attendees",
    tickets: "Tickets",
    checkin: "Checkin",
    users: "Users",
    eventTypes: "Event Types",
    userTypes: "User Types",
    roles: "Roles",
    memberType: "Member Types",
    emailTemplate: "Email Template",
    coupons: "Coupons",
    company: "Company",
    plans: "Plans",
    home: "Home",
    profile: "Profile",
    logout: "Logout",
    action: "Action",
    edit: "Edit",
    add: "Add",
    delete: "Delete",
    save: "Save",
    update: "Update",
    view: "View",
    search: "Search",
    cancel: "Cancel",
    preview: "Preview",
    deleteMsg: "Are you sure want to delete?",
    cancelMsg: "Are you sure want to cancel?",
    name: "Name",
    firstName: "First Name",
    lastName: "Last Name",
    email: "Email",
    phoneNumber: "Phone Number",
    phone: "Phone",
    status: "Status",
    submit: "Submit",
    createdOn: "Created On",
    active: "Active",
    inactive: "Inactive",
    yes: "Yes",
    no: "No",
    title: "Title",
  },
  event: {
    selectEventMode: "Select Event Mode",
    offline: "Offline",
    online: "Online",
    hybrid: "Hybrid",
    upcoming: "Upcoming",
    ongoing: "Ongoing",
    completed: "Completed",
    createEvent: "Create Event",
    noEventsFound: "No Events Found",
    duplicate: "Duplicate",
    addEvent: "Add Event",
    selectEventType: "Select Event Type",
    eventTitle: "Event Title",
    startDate: "Start Date",
    endDate: "End Date",
    eventDescription: "Event Description",
    writeSomethingAwesome: "Write something awesome...",
    eventInstructions: "Event Instructions",
    eventCapacity: "Event Capacity",
    paid: "Paid?",
    eventPrice: "Event Price",
    addLocation: "Add Location",
    uploadCoverImage: "Upload Cover Image",
    uploadFloorPlan: "Upload Floor Plan",
    addSubEvent: "Add Sub Event",
    subEvents: "Sub Events",
    location: "Location",
    eventLocation: "Event Location",
    locationRequiredMsg: "Please Enter Location",
    multipleLocations: "Multiple Locations",
    crewMembers: "Crew Members",
    selectMemberType: "Select Member Type",
    addMember: "Add Member",
    updateOngoingEventMsg: "You can not modify ongoing or past events.",
    updateRSVPEventMsg: "Please delete RSVP and other related data before modifying the event.",
    deleteOngoingEventMsg: "You can not delete ongoing or past events.",
    deleteRSVPEventMsg: "Please delete RSVP and other related data before deleting the event.",
    editEvent: "Edit Event",
    free: "Free",
    about: "About",
    description: "Description",
    noDescriptionAdded: "No Description added",
    instructions: "Instructions",
    noInstructionsAdded: "No Instructions added",
    startDateTime: "Start Date/Time",
    endDateTime: "End Date/Time",
    eventMode: "Event Mode",
    selectEventTitle: "Select Event Title",
    event: "Event",
    importEvent: "Import Event",
    importRSVP: "Import RSVP",
    importEventWithRSVP: "Create Event with RSVP",
    singleEvent: "Single Event",
    multipleEvents: "Multiple Events",
    viewEvent: "View Event",
    saveAsDraft: "Save as Draft",
    noLocations: "No locations",
    noLink: "No link",
    noLinkProvidedbyContact: "No link provided by the contact",
    addOns: "Add-ons",
    eventFeatures: "Event Features",
    feedbackForm: "Feedback form",
    feedbackFormDescription: "Create custom feedback forms to be filled by attendees post event",
    agenda: "Agenda",
    agendaDescription: "Define event agenda and their team members",
    faqs: "FAQs",
    faqDescription: "Create an faq section for event viewers",
    customRSVP: "Custom RSVP",
    customRSVPDescription: "Define RSVP forms for the event",
    selectFromExisting: "Select from existing",
    createYourOwn: "Create your own",
    selectFeedbackForm: "Select feedback form",
    selectAgenda: "Select Agenda",
    selectFAQ: "Select FAQ",
    selectRSVPForm: "Select RSVP Form",
    requestFeedback: "Request Feedback",
    userResponses: "User Responses",
    noMembersAdded: "No Members added",
    viewingDraftEvent: "You are viewing a draft event.",
    reoccurringFromPreviousEvent: "Reoccurring from a previous event ?",
  },
  rsvp: {
    requiredFieldAtleastone: "Please select at least one option",
    requiredField: "Required field",
    published: "Published",
    draft: "Draft",
    cancelled: "Cancelled",
    formDesign: "Form design",
    details: "Details",
    createdBy: "Created By",
    copyRSVP: "Copy RSVP",
    addRSVP: "Add RSVP",
    editRSVP: "Edit RSVP",
    viewRSVP: "View RSVP",
    generalSettings: "General Settings",
    designForm: "Design Form",
    configureReminders: "Configure Reminders",
    viewPublish: "View & Publish",
    rsvpByDate: "RSVP By Date",
    rsvpByTime: "RSVP By Time",
    allowGuestsToDo: "Allow Guests to do",
    individualRSVP: "Individual RSVP",
    setLimitForPlusOnes: "Set limit for Plus-ones",
    mentionPlusOne: "Does the primary invitee need to mention the name of the plus-ones?",
    emailAddressSupport: "Email Address (Support)",
    contactNoSupport: "Contact No (Support)",
    firstReminderMsg:
      "Set the first reminder email for the primary attendee to submit the names of their plus-ones",
    secondReminderMsg:
      "Set the second/last reminder email data for the primary attendee to submit the names of their plus-ones",
    firstReminderInfo:
      "This email will be sent as a <br /> 1st reminder to the primary <br />attendee to submit the name <br /> of their plus-ones.",
    firstReminderDate: "First reminder date for primary attendee info",
    secondReminderDate: "Second reminder date for primary attendee info",
    prev: "Prev",
    next: "Next",
    rsvpFormDesign: "RSVP Form Design",
    basic: "Basic",
    custom: "Custom",
    basicFormFields: "Basic Form Fields",
    customFormFields: "Custom Form Fields",
    selectCustomTemplate: "Select Custom Form Template",
    selectTemplate: "Select Template",
    pleaseLetUsKnow: "Please let us know if you will be able to make it.",
    yesIllBeThere: "Yes, I'll be there",
    noIcantMakeIt: "No, I can't make it",
    useBasicFormMsg: "I would like to use basic form for my event",
    useForm: "Use Form",
    typeAQuestion: "Type a Question",
    selectFieldType: "Select Field type",
    phone: "Phone",
    textbox: "Textbox",
    textarea: "Textarea",
    checkbox: "Checkbox",
    dropdown: "Dropdown",
    radioButton: "Radio button",
    addFormElement: "Add Form Element",
    useCustomFormMsg: "I would like to use custom form for my event",
    designEmailTemplates: "Design Default or recurring email templates",
    default: "Default",
    reminder: "Reminder",
    messageWhen: "Message When",
    rsvpIsYes: "RSVP is Yes",
    rsvpIsNo: "RSVP is No",
    reminderDate1st: "1st reminder date has approached for attendee details",
    reminderDate2nd: "2nd reminder date has approached for attendee details",
    registrationClosed: "Registration is to be closed",
    eventDateApproached: "Event date is approached",
    subject: "Subject",
    typeAMessage: "Type a message",
    attachPaymentReceipt: "Attach payment receipt",
    sendLinkToNamePlusOnes: "Send Link to name the plus-ones",
    previewToRsvp: "A preview to the RSVP",
    contactInfo: "Contact Info",
    reservation: "Reservation",
    guestInfo: "Guest Info",
    fillPrimaryInfoTitle: "Check the checkbox to set primary details",
    publishRSVPPage: "Publish RSVP Page",
    rsvpLinkCopied: "Your RSVP Link has been Copied.",
    thankYou: "Thank you!",
    alreadySubmitted: "You have already submitted RSVP Form.",
    reachUsForAssistance: "You can reach us for any assistance:",
    formSubmitted: "Your RSVP Form has been submitted successfully.",
    eventAtFullCapacity: "This event is at full capacity.",
    thankYouForYourInterest: "Thank you for your interest!",
    rsPerPerson: "Rs/Person",
    total: "Total:",
    payWithCard: "Pay with Card",
    payNow: "Pay Now",
    reasonToRefuse: "Reason to refuse",
    additionalComments: "Additional comments / Mention your reason",
    dateNotSuitable: "Date not suitable",
    timeNotSuitable: "Time not suitable",
    priceTooHigh: "Event price is too high",
    someOtherPlan: "Have some other plans",
    notInterested: "Not interested",
    reasonNotMentioned: "My reasons are not mentioned here",
    rsvpSubmittedSuccessfully: "RSVP Submitted successfully!",
    errorSubmittingRSVP: "Error Submitting RSVP!",
    unauthorizedAccess: "Access unauthorized. Please try again.",
    rsvpGuestFormSubmitted: "Your RSVP Guest Form has been submitted successfully.",
    guestInfoSubmitted: "Guest info Submitted successfully!",
    errorSubmittingGuestInfo: "Error submitting guest info!",
    primaryAttendeeName: "Primary Attendee Name",
    eventName: "Event Name",
    youCanNotAddGuest: "You can not add guests for this event",
    noGuestsAreAdded: "No guests are added",
    addGuest: "Add Guest",
    yes: "Yes",
    no: "No",
    rsvpBy: "RSVP By",
    fieldOptions: "Field options",
    addCommaSeperatedOptions: "Add comma seperated options",
    basicPlusCustomFormtext: "Basic + Custom form fields will be visibed on RSVP form",
  },
  connection: {
    newConnection: "New Connection",
    upload: "Upload",
    remove: "Remove",
    website: "Website",
    socialMediaLinks: "Social Media Links",
    addSocialMediaLink: "Add Social Media Link",
    editConnection: "Edit Connection",
    groupName: "Group Name",
    members: "Members",
    viewMember: "View Member",
    newGroup: "New Group",
    editGroup: "Edit Group",
    groupMembers: "Group Members",
    or: "OR",
    groupDeleteNote: "Note: This will not delete any members.",
  },
  invitation: {
    sent: "Sent",
    request: "Request",
    date: "Date",
    sendInvite: "Send Invite",
    to: "To",
    chooseGroup: "Choose a group",
    eventTitleInfo:
      "The event title will be displayed for the events which have published RSVP & Ticket settings exist.",
    send: "Send",
    viewInvite: "View Invite",
    requested: "Requested",
  },
  attendees: {
    totalAmtPaid: "Total Amt Paid",
    maxLimit: "Max Limit",
    accepted: "Accepted",
    rejected: "Rejected",
    remaining: "Remaining",
    closingDate: "Closing Date",
    viewAttendee: "View attendee",
    payment: "Payment",
    plusOnes: "Plus-Ones",
    history: "History",
    personalInformation: "Personal Information",
    eventsAttending: "Events Attending",
    paymentReceipt: "Payment Receipt",
    viewAsPDF: "View as PDF",
    paymentInformation: "Payment Information",
    receiptNo: "Receipt No",
    transactionID: "Transaction ID",
    transactionDetails: "Transaction Details",
    paymentMethod: "Payment Method",
    tax: "Tax",
    dateReceived: "Date Received",
    subTotal: "SubTotal",
    totalPaymentReceived: "Total Payment Received",
    noPaymentReceived: "No Payment received",
    detailsOfPlusOnes: "Details of Plus-ones",
    noUserAttendeesAvailable: "No user attendees available",
    historyEmailTransactions: "History - Email transactions",
    noEmailLogsAvailable: "No Email logs available",
  },
  ticket: {
    whatYourTicketShouldConsist: "What your tickets should consists ?",
    enableAll: "Enable All",
    qrCodeId: "QR Code & ID",
    regularVIP: "Regular/VIP",
    primaryAttendeeNamePlusOne: "Primary attendee name (Plus-ones count)",
    separateTicketforPlusOne: "Separate ticket for Primary and Plus Ones Names",
    mainEventTitle: "Main Event Title",
    individualEventTitle: "Individual Event Title",
    venue: "Venue",
    eventDateTime: "Event Date & Time",
    seats: "Seats",
    notes: "Notes",
    termsConditions: "Terms & Conditions",
    attendeeName: "Attendee Name",
  },
  checkIn: {
    checkInTime: "Check-in Time",
  },
  user: {
    usertype: "User Type",
    role: "Role",
    password: "Password",
    newUser: "New User",
    editUser: "Edit User",
    selectUserType: "Select User Type",
    selectRole: "Select Role",
  },
  eventType: {
    eventtype: "Event Type",
    newEventType: "New Event Type",
    editEventType: "Edit Event Type",
  },
  userType: {
    newUserType: "New User Type",
    editUserType: "Edit User Type",
  },
  coupon: {
    couponSavings: "coupon savings",
    toPay: "To Pay",
    applied: "Applied!",
    discount: "Discount",
    couponCriteriaNotMatch: "Coupon criteria doesn't match!",
    invalidCoupon: "Invalid coupon code! Please try with different code.",
    selectCoupons: "Select Coupons",
    typeCouponCodeHere: "Type coupon code here",
    apply: "Apply",
    viewCoupons: "View coupons",
    copyCode: "Copy code",
    copiedCouponCode: "Copied coupon code",
    viewDetails: "View details",
    paymentCouponForYou: "Payment coupons for you",
    addCoupon: "Add Coupon",
    newCoupon: "New Coupon",
    editCoupon: "Edit Coupon",
    validTill: "Valid till",
    fixedDiscount: "Fixed Discount",
    percentageDiscount: "Percentage Discount",
    noOfTickets: "No. of Tickets",
    couponName: "Coupon Name",
    couponCode: "Coupon Code",
    criteria: "Criteria",
    field: "Field",
    type: "Type",
    number: "Number",
    condition: "Condition",
    addMoreCriteria: "Add More Criteria",
  },
  role: {
    roleName: "Role Name",
    newRole: "New Role",
    editRole: "Edit Role",
    standard: "Standard",
    effectedModules: "Effected Modules",
    userroles: "User Roles",
    emailtemplate: "Email Template",
    invitations: "Invitations",
  },
  memberType: {
    newMemberType: "New Member Type",
    editMemberType: "Edit Member Type",
  },
  emailTemplate: {
    id: "ID",
    keywords: "Keywords",
    uniqueIdentifier: "Unique Identifier",
    editEmailTemplate: "Edit Email Template",
  },
  postEvent: {
    feedback: "Feedback",
    faq: "FAQ",
    agenda: "Agenda",
    customForm: "Custom Form",
    newForm: "New Form",
    questions: "Questions",
    question: "Question",
    numberOfStar: "Number of Star",
    formTitle: "Form title",
    addMoreQuestion: "Add More Question",
    forms: "Forms",
    formType: "Form Type",
    feedbackFrom: "Feedback Form",
    agendaForm: "Agenda Form",
    faqForm: "FAQ Form",
    customRsvpForm: "Custom RSVP Form",
    title: "Title",
    startTime: "Start Time",
    endTime: "End Time",
    hostArtistName: "Host/Artist Name",
    description: "Description",
    addMoreHostArtistName: "Add comma separated value to add more",
    answer: "Answer",
    agendaSubtitle:
      "Add an itinerary, schedule, or lineup to your event. You can include a time, a  description of what will happen, and who will host or perform during the event. (Ex. Speaker, performer, artist, guide, etc.) If your event has multiple dates, you can add a second agenda.",
    faqSubtitle:
      "Answer questions your attendees may have about the event, like parking, accessibility and refunds.",
    addMore: "Add More",
  },
  reports: {
    selectModule: "Select Module",
    selectFields: "Select Fields",
    generate: "Generate",
  },
  profile: {
    account: "Account",
    general: "General",
    security: "Security",
    gender: "Gender",
    male: "Male",
    female: "Female",
    address: "Address",
    oldPassword: "Old Password",
    newPassword: "New Password",
    confirmNewPassword: "Confirm New Password",
    updatePassword: "Update Password",
  },
  emptyState: {
    noOptions: "No options",
    noRowsFound: "No rows found",
    noDataAvailableSelectEvent: "No data available, Please select event first.",
    noDataAvailableSelectField: "No Data Available, Please select fields first.",
    noTicketsGenerated: "No tickets are generated for this event.",
  },
  upload: {
    uploadPhoto: "Upload photo",
    filesAllowed: "Allowed *.jpeg, *.jpg, *.png, *.gif",
    allowedCSV: "Allowed *.csv file only",
    downloadCSV: "Download a sample CSV format",
    maxSize: "max size of {{size}}",
    dropOrSelectFile: "Drop or Select file",
    dropFilesHereOrBrowse: "Drop files here or click <1> browse </1> through your machine",
  },
  toast: {
    success: {
      login: "User logged in successfully",
      logout: "User logged out successfully.",
      loginAgain: "Please login again!",
      inactiveLogout: "Logged out due to inactivity",
      deleteCompany: "Company record deleted successfully!",
      deleteConnection: "Connection deleted successfully!",
      deleteGroup: "Group deleted successfully!",
      updateGroup: "Group updated successfully!",
      resetPasswordLink: "You will receive a reset password link shortly!",
      deleteInvitation: "Invitation record deleted successfully!",
      deletePlan: "Plan deleted successfully!",
    },
    error: {
      postEventList: "Error fetching post event list",
      action: "Failed to perform operation!",
      companyList: "Error fetching company list",
      connectionList: "Error fetching connections",
      deleteConnection: "Error deleting connection",
      groupList: "Error fetching groups",
      deleteGroup: "Error deleting group",
      selectMember: "Please select at least one member!",
      checkinList: "Error fetching check in list",
      eventRequiredFields: "Please fill required fields: event type, mode, title & date time",
      subEventRequiredFields:
        "Please fill required fields in sub event(s): event type, mode, title & date time",
      eventCapacity: "Parent event capacity is not valid, please enter value greater than 0",
      subEventCapacity: "Sub event capacity is not valid, please enter value greater than 0",
      eventList: "Error fetching events",
      invitationList: "Error fetching invitations",
      invitationRequiredFields: "Please add recipient email or select at least one group!",
      invalidCredentials: "Username or password incorrect",
      eventTypeList: "Error fetching event types",
      memberTypeList: "Error fetching member type",
      roleList: "Error fetching roles",
      userList: "Error fetching users",
      userType: "Error fetching user types",
      coupon: "Error fetching coupons",
      plan: "Error fetching plans",
      passwordIncorrect: "Password incorrect",
      rsvpList: "Error fetching RSVP list",
      deleteRSVP: "Error deleting RSVP record",
      rsvpLessThanEvent: "RSVP date should be less than event date is ({{date}})",
      firstReminderLessThanEvent: "1st reminder date should be less than event date is ({{date}})",
      secondReminderGreaterThanFirstReminder:
        "2nd reminder date should be greater than 1st reminder date",
      secondReminderLessThanEvent: "2nd reminder date should be less than event date is ({{date}})",
      basicFormCheck: "Please check the basic form checkbox",
      customFormCheck: "Please check the custom form checkbox",
    },
  },
  company: {
    companyList: "Company List",
    createCompany: "Create Company",
    plan: "Plan",
    planEnd: "Plan End",
    domain: "Domain",
    editCompany: "Edit Company",
    companyName: "Company Name",
    companyEmail: "Company Email",
    companyPhone: "Company Phone No.",
    industryType: "Industry Type",
    noOfEmployee: "No. of Employee",
    companyWebsite: "Company Website",
    companyAddress: "Company Address",
    selectCountry: "Select Country",
    selectState: "Select State",
    selectCity: "Select City",
    selectPlan: "Select Plan",
    manageConfig: "Manage Config",
    eventCreateLimit: "Event Creation Limit",
    connectionLimit: "Connection Limit",
    invitationSentLimit: "Send invitation Limit",
    userCreateLimit: "User Creation Limit",
    postEventCreateLimit: "Post event form Creation Limit",
    domainSubscription: "Domain Subscription end date",
    publicEventRequestAllow: "Allow anonymous requests",
    customFormAllow: "Allow custom form creation",
    agendaAllow: "Allow agenda creation",
    faqAllow: "Allow faq creation",
    eventFeedbackFormAllow: "Allow event feedback form creation",
    passwordValidation: "Password must be minimum 8+",
  },
  plan: {
    createPlan: "Create Plan",
    editPlan: "Edit Plan",
    planName: "Plan Name",
    planType: "Plan Type",
    planDetails: "Plan Details",
    monthly: "Monthly",
    quarterly: "Quarterly",
    biannually: "Biannually",
    yearly: "Yearly",
    price: "Price",
    billing: "Billing",
  },
  dashboard: {
    eventsByModes: "Events by Modes",
    invited: "Invited",
    requestedSocialMedia: "Requested (Social media)",
    eventTypeStatistics: "Event type Statistics",
    noOfAttendeeStatistics: "No. of attendees Statistics",
    noOfTicketsPurchasedStatistics: "No. of tickets purchased Statistics",
    eventBaseTicketAndCheckIn: "No. of tickets/check-in based on Events",
    eventBaseTicketAndCheckInInfo:
      "By default, all events are previewed which do not have a sub event, to compare sub events internally select the event name",
    allEvents: "All Events",
    paid: "Paid",
  },
  organization: {
    myOrganization: "My Organization",
    companyDetails: "Company Details",
    details: "Details",
    plan: "Plan",
    help: "Help",
    Label: {
      logo: "Logo",
      name: "Name",
      email: "Email",
      contactNo: "Contact No",
      address: "Address",
      domain: "Domain",
      country: "Country",
      state: "State",
      city: "City",
      website: "Website",
      noOfEmployee: "No. of Employee",
      industryType: "Industry Type",
      planId: "Plan Id",
      planName: "Plan Name",
      planStartDate: "Plan Start Date",
      planEndtDate: "Plan End Date",
      cancelPlan: "Cancel Plan",
      upgradePlan: "Upgrade Plan",
      subject: "Subject",
      description: "Description",
      submit: "Submit",
      agendaAllow: "Agenda Allow",
      customFormAllow: "Custom Form Allow",
      domainSubscription: "Domain Subscription",
      eventFeedbackFormAllow: "Event Feedback Form Allow",
      eventCreateLimit: "Event Create Limit",
      faqAllow: "FAQ Allow",
      publicEventRequestAllow: "Public Event Request Allow",
      invitationSentLimit: "Invitation Sent Limit",
      postEventCreateLimit: "Post Event Create Limit",
      connectionLimit: "Connection Limit",
      userCreateLimit: "User Create Limit",
      current: "Current",
      configurations: "Configurations",
    },
  },
}
