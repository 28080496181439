import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  data: [],
}

export const couponSlice = createSlice({
  name: "coupons",
  initialState,
  reducers: {
    setCoupons: (state, action) => {
      state.data = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setCoupons } = couponSlice.actions

export default couponSlice.reducer
