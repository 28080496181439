import React, { createContext, useContext, useState } from "react"

const PermissionsContext = createContext()

export const PermissionsProvider = ({ children }) => {
  const [userPermissions, setUserPermissions] = useState(
    JSON.parse(localStorage.getItem("currentUser"))?.role?.rolePermissions
  )

  const setPermissions = (permissions) => {
    setUserPermissions(permissions)
  }

  return (
    <PermissionsContext.Provider value={{ userPermissions, setPermissions }}>
      {children}
    </PermissionsContext.Provider>
  )
}

export const usePermissions = () => {
  const context = useContext(PermissionsContext)
  if (!context) {
    throw new Error("usePermissions must be used within a PermissionsProvider")
  }
  return context
}
