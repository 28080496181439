import { merge } from "lodash"
import PropTypes from "prop-types"

import Chart, { useChart } from "src/components/Chart"

const LineChart = ({ series, options }) => {
  const chartOptions = useChart(
    merge(options, {
      chart: {
        height: 350,
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        tooltipHoverFormatter: function (val, opts) {
          return (
            val +
            " - <strong>" +
            opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] +
            "</strong>"
          )
        },
      },
      markers: {
        size: 0,
        hover: {
          sizeOffset: 6,
        },
      },
      tooltip: {
        x: {
          show: false,
        },
        y: [
          {
            title: {
              formatter: function (val) {
                return val
              },
            },
          },
          {
            title: {
              formatter: function (val) {
                return val
              },
            },
          },
          {
            title: {
              formatter: function (val) {
                return val
              },
            },
          },
        ],
        marker: { show: false },
      },
      grid: {
        borderColor: "#f1f1f1",
      },
    })
  )

  return <Chart dir="ltr" type="line" series={series || []} options={chartOptions} height={350} />
}

export default LineChart

LineChart.propTypes = {
  series: PropTypes.array,
  options: PropTypes.object,
}
