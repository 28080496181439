import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  data: [],
}

export const eventSlice = createSlice({
  name: "events",
  initialState,
  reducers: {
    setEvent: (state, action) => {
      state.data = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setEvent } = eventSlice.actions

export default eventSlice.reducer
