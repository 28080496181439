import { Fragment, useEffect, useState } from "react"
import { formatInTimeZone } from "date-fns-tz"
import { useDispatch, useSelector } from "react-redux"
import i18n from "src/translations"
import {
  Autocomplete,
  Box,
  Card,
  CircularProgress,
  Container,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import { Refresh } from "@mui/icons-material"

import { setCheckin } from "src/redux/slices/checkin/checkinSlice"

import Searchbar from "src/layouts/Dashboard/Common/Searchbar"

import CustomBreadcrumbs from "src/components/CustomBreadcrumbs"
import { useSnackbar } from "src/components/Snackbar"
import DataGridBasic from "src/components/DataGridBasic"
import Meta from "src/components/Meta"

import checkinService from "src/services/checkin.service"
import eventService from "src/services/event.service"

const CheckinView = () => {
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()
  const checkinData = useSelector((state) => state.checkin.data)
  const [isLoading, setIsLoading] = useState(false)
  const [isSearched, setIsSearched] = useState(false)
  const [eventData, setEventData] = useState([])
  const [eventDetails, setEventDetails] = useState(null)
  const [isLoadingEventList, setIsLoadingEventList] = useState(false)
  const [page, setPage] = useState(0)

  const translations = {
    listError: i18n.t("toast:error:checkinList"),
    noData: i18n.t("emptyState:noDataAvailableSelectEvent"),
  }

  const eventStatusOptions = [
    { id: "offline", label: i18n.t("event:offline") },
    { id: "online", label: i18n.t("event:online") },
    { id: "hybrid", label: i18n.t("event:hybrid") },
  ]

  const handlechangeevent = (eventMode) => {
    if (eventMode) {
      getAllEvents(eventMode)

      setEventDetails(null)
    }
  }

  const getAllEvents = (eventMode) => {
    setIsLoadingEventList(true)
    eventService
      .getEvents(eventMode, true)
      .then((res) => {
        setIsLoadingEventList(false)
        if (res.status) {
          setEventData(res.data.data)
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }

  const handlechangepevent = (eventInfo) => {
    if (eventInfo.id) {
      setEventDetails(eventInfo)
    }
  }

  const getCheckins = (event) => {
    if (event?.page && isSearched) {
      return
    }

    setIsLoading(true)

    event?.page != undefined ? setPage(event) : (event = { ...event, ...page })

    checkinService
      .getCheckin(eventDetails?.id, event)
      .then((res) => {
        const resData = res.data.data

        setIsLoading(false)

        if (res.status) {
          dispatch(setCheckin(resData))
        } else {
          enqueueSnackbar(translations.listError, { variant: "error" })

          dispatch(setCheckin([]))
        }
      })
      .catch((error) => {
        enqueueSnackbar(translations.listError, { variant: "error" })
      })
  }

  useEffect(() => {
    if (eventDetails?.id) {
      getCheckins()
    }

    return () => {
      dispatch(setCheckin([]))
    }
  }, [eventDetails])

  useEffect(() => {
    const timer = setInterval(() => {
      if (!isLoading && isSearched) setIsSearched(false)
    }, 1000)

    if (!isSearched) {
      clearTimeout(timer)
    }

    return () => clearTimeout(timer)
  }, [isLoading, isSearched])

  const columns = [
    {
      headerName: "Checkin ID",
      field: "id",
      flex: 1,
    },
    {
      headerName: i18n.t("global:name"),
      field: "primaryAttendeeName",
      width: 250,
      hideable: false,
    },
    {
      headerName: i18n.t("global:email"),
      field: "emailId",
      width: 320,
    },
    {
      headerName: i18n.t("event:event"),
      field: "eventTitle",
      width: 320,
      renderCell: (params) => (
        <Stack spacing={1} direction="row">
          <Typography
            whiteSpace="normal"
            variant="body2"
            sx={{
              overflow: "hidden",
              WebkitLineClamp: 2,
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              textOverflow: "ellipsis",
            }}
          >
            {params.row.eventId?.eventTitle}
          </Typography>
        </Stack>
      ),
    },
    {
      headerName: i18n.t("checkIn:checkInTime"),
      field: "createdAt",
      width: 250,
      renderCell: (params) => {
        return <p>{formatInTimeZone(params.row.createdAt, "", "yyyy-MM-dd 'at' hh:mm a")}</p>
      },
    },
  ]

  return (
    <Fragment>
      <Meta title={"Check-in | EventOPS"} />
      <Container maxWidth="xl">
        <Stack direction="row" spacing={2} alignItems="center" mb={2} flexWrap="wrap" useFlexGap>
          <Box sx={{ flexGrow: 1 }}>
            <CustomBreadcrumbs
              heading={i18n.t("global:checkin")}
              links={[{ name: i18n.t("global:checkin") }]}
              sx={{ mb: 0 }}
            />
          </Box>
          <Box>
            <Searchbar
              onSearch={(text) => {
                setIsSearched(true)
                if (text && text != "") getCheckins({ search: text })
              }}
              clearSearch={() => {
                setIsSearched(true)
                getCheckins()
              }}
            />
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }} flexWrap="wrap" useFlexGap>
            <Stack direction="row" spacing={1} flexWrap="wrap" useFlexGap>
              {eventDetails?.id && (
                <Refresh
                  sx={{ cursor: "pointer", color: "text.secondary", m: 1 }}
                  onClick={getCheckins}
                />
              )}
              <Autocomplete
                disablePortal
                disableClearable
                id="event-status"
                size="small"
                options={eventStatusOptions}
                noOptionsText={i18n.t("emptyState:noOptions")}
                onChange={(event, item) => {
                  handlechangeevent(item.id)
                }}
                getOptionLabel={(option) => option.label}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField {...params} label={i18n.t("event:selectEventMode")} />
                )}
                sx={{ width: { xs: "160px", sm: "200px", md: "200px" } }}
              />
              <Autocomplete
                disablePortal
                disableClearable
                id="event-title"
                size="small"
                options={eventData}
                noOptionsText={i18n.t("emptyState:noOptions")}
                onChange={(event, item) => {
                  handlechangepevent(item)
                }}
                value={eventDetails}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.eventTitle}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={i18n.t("event:selectEventTitle")}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <Fragment>
                          {isLoadingEventList ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </Fragment>
                      ),
                    }}
                  />
                )}
                // filterOptions={(x) => x}
                loading={isLoadingEventList}
                sx={{ width: { xs: "160px", sm: "200px", md: "200px" } }}
              />
            </Stack>
          </Box>
        </Stack>
        <Card>
          <DataGridBasic
            data={checkinData?.data || []}
            columns={columns}
            rowCount={checkinData.meta?.totalItems || 10}
            loading={isLoading}
            paginationMode="server"
            onPaginationModelChange={getCheckins}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  id: false,
                },
              },
              pagination: {
                paginationModel: {
                  pageSize: checkinData.meta?.itemsPerPage || 10,
                },
              },
              sorting: {
                sortModel: [{ field: "createdAt", sort: "desc" }],
              },
            }}
            emptyContentTitle={!eventDetails && translations.noData}
          />
        </Card>
      </Container>
    </Fragment>
  )
}

export default CheckinView
