import { useEffect, useState } from "react"
import PropTypes from "prop-types"

import { useTheme, alpha } from "@mui/material/styles"
import { Box, Stack, Drawer, Avatar, Typography } from "@mui/material"
import {
  AdminPanelSettings,
  AssignmentInd,
  AssignmentTurnedIn,
  BookOnlineRounded,
  Business,
  Contacts,
  Dns,
  Equalizer,
  Event,
  EventAvailable,
  EventNote,
  EventSeat,
  Payments,
  PendingActions,
  People,
  Summarize,
} from "@mui/icons-material"

import { bgBlur } from "src/theme/css"

import { checkRolePermissions } from "src/utils/checkRolePermissions"

import { usePathname } from "src/routes/hooks"
import { usePermissions } from "src/routes/PermissionRoute"

import i18n from "src/translations"

import useResponsive from "src/hooks/useResponsive"
import Scrollbar from "src/components/Scrollbar"
import { NAV } from "../../constants/ConfigLayout"
import Iconify from "src/components/Iconify"
import NavItem from "./NavItem"

const Nav = ({ openNav, onCloseNav }) => {
  const pathname = usePathname()
  const theme = useTheme()
  const { userPermissions } = usePermissions()
  const upLg = useResponsive("up", "lg")
  const [account, setAccount] = useState(JSON.parse(localStorage.getItem("currentUser")))

  useEffect(() => {
    if (openNav) {
      onCloseNav()
    }
  }, [pathname])

  const navConfig = [
    {
      id: "dashboard",
      title: i18n.t("global:dashboard"),
      path: "/app/dashboard",
      icon: <Equalizer />,
    },
    {
      id: "events",
      title: i18n.t("global:events"),
      path: "/app/view-events",
      icon: <Event />,
    },
    {
      id: "rsvp",
      title: i18n.t("global:rsvp"),
      path: "/app/view-rsvp",
      icon: <EventAvailable />,
    },
    {
      id: "connections",
      title: i18n.t("global:connections"),
      path: "/app/view-connections",
      icon: <Contacts />,
    },
    {
      id: "invitations",
      title: i18n.t("global:invitation"),
      path: "/app/view-invitations",
      icon: <EventNote />,
    },
    {
      id: "company",
      title: i18n.t("global:company"),
      path: "/app/view-company",
      icon: <Business />,
    },
    {
      id: "plans",
      title: i18n.t("global:plans"),
      path: "/app/view-plans",
      icon: <Payments />,
    },
    {
      id: "eventoperations",
      title: i18n.t("global:eventOperations"),
      path: "",
      icon: <PendingActions />,
      children: [
        {
          id: "attendees",
          title: i18n.t("global:attendees"),
          path: "/app/view-attendees",
          icon: <EventSeat />,
        },
        {
          id: "tickets",
          title: i18n.t("global:tickets"),
          path: "/app/view-tickets",
          icon: <BookOnlineRounded />,
        },
        {
          id: "checkin",
          title: i18n.t("global:checkin"),
          path: "/app/view-checkin",
          icon: <AssignmentTurnedIn />,
        },
      ],
    },
    {
      id: "masters",
      title: i18n.t("global:master"),
      path: "",
      icon: <Dns />,
      children: [
        {
          id: "users",
          title: i18n.t("global:users"),
          path: "/app/view-users",
          icon: <AssignmentInd />,
        },
        {
          id: "eventtype",
          title: i18n.t("global:eventTypes"),
          path: "/app/view-eventtype",
          icon: <Iconify icon="material-symbols:event-list" />,
        },
        {
          id: "usertype",
          title: i18n.t("global:userTypes"),
          path: "/app/view-usertype",
          icon: <Iconify icon="fa6-solid:user-tag" />,
        },
        {
          id: "userroles",
          title: i18n.t("global:roles"),
          path: "/app/view-userroles",
          icon: <AdminPanelSettings />,
        },
        {
          id: "participants",
          title: i18n.t("global:memberType"),
          path: "/app/view-membertype",
          icon: <People />,
        },
        {
          id: "emailtemplate",
          title: i18n.t("global:emailTemplate"),
          path: "/app/view-emailtemplate",
          icon: <Iconify icon="fluent:mail-template-24-filled" />,
        },
        {
          id: "coupon",
          title: i18n.t("global:coupons"),
          path: "/app/view-coupons",
          icon: <Iconify icon="streamline:discount-percent-coupon-solid" />,
        },
      ],
    },
    {
      id: "postevent",
      title: i18n.t("global:postEvent"),
      path: "/app/post-event",
      icon: <Iconify icon="material-symbols:calendar-add-on-rounded" width={27} />,
    },
    {
      id: "reports",
      title: i18n.t("global:reports"),
      path: "/app/view-reports",
      icon: <Summarize />,
    },
  ]

  const renderAccount = () => (
    <Box
      sx={{
        my: 3,
        mx: 2.5,
        py: 2,
        px: 2.5,
        display: "flex",
        borderRadius: 1.5,
        alignItems: "center",
        bgcolor: (theme) => alpha(theme.palette.grey[500], 0.12),
      }}
    >
      <Avatar src={account?.profilePic} alt="photoURL" />
      <Box sx={{ ml: 2 }}>
        <Typography variant="subtitle2">{account?.firstName}</Typography>
        <Typography variant="body2" sx={{ color: "text.secondary" }}>
          {account?.role?.roleName}
        </Typography>
      </Box>
    </Box>
  )

  const showChildren = (item) => {
    if (item?.children) {
      const activeChild = []

      item?.children?.map((child) => {
        if (checkRolePermissions(userPermissions, child.id, "isView")) {
          activeChild.push(child)
        }
      })

      return activeChild?.length > 0
    }

    return true
  }

  const renderMenu = () => (
    <Stack component="nav" spacing={0.5} sx={{ px: 2 }}>
      {navConfig.map(
        (item) =>
          checkRolePermissions(userPermissions, item.id, "isView") &&
          showChildren(item) && <NavItem key={item.title} item={item} />
      )}
    </Stack>
  )

  const renderContent = () => (
    <Scrollbar
      sx={{
        height: 1,
        ...bgBlur({
          color: theme.palette.grey[300],
          blur: 0.2,
        }),
        "& .simplebar-content": {
          height: 1,
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      {renderAccount()}
      {renderMenu()}
    </Scrollbar>
  )

  return (
    <Box
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.WIDTH },
      }}
    >
      {upLg ? (
        <Box
          sx={{
            height: 1,
            position: "fixed",
            width: NAV.WIDTH,
            borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
          }}
        >
          {renderContent()}
        </Box>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          PaperProps={{
            sx: {
              width: NAV.WIDTH,
            },
          }}
        >
          {renderContent()}
        </Drawer>
      )}
    </Box>
  )
}

export default Nav

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
}
